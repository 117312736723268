<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-flag </v-icon>
              Events
              <v-spacer/>
              <v-btn
                x-small
                dark
                @click="haddleClick()"
              >
                <v-icon left x-small>
                  mdi-plus
                </v-icon>
                Add Event
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
  
              <v-data-table
                :headers="headers"
                :items="eventList"
                single-select                
                return-object
                class="elevation-5"
              >
              <template v-slot:item.EventName="{ item }">
                  {{ item.EventName ? item.EventName : 'No data available' }}
              </template>
              <template v-slot:item.year="{ item }">
                  {{ item.year ? item.year : 'No data available' }}
              </template>
              <template v-slot:item.ShowInPOS="{ item }">
                  {{ item.ShowInPOS ? item.ShowInPOS : 'No data available' }}
              </template>
              <template v-slot:item.CardSurchargePercentage="{ item }">
                  {{ item.CardSurchargePercentage ? item.CardSurchargePercentage : 'No data available' }}
              </template>
              <template v-slot:item.PaymentProfile="{ item }">
                  {{ item.PaymentProfile ? item.PaymentProfile.join(', ') : 'No data available' }}
              </template>
              <template v-slot:item.PrintingProfile="{ item }">
                  {{ item.PrintingProfile ? item.PrintingProfile : 'No data available' }}
              </template>
              <template v-slot:item.StandardDrinkLimit="{ item }">
                  {{ item.StandardDrinkLimit ? item.StandardDrinkLimit : 'No data available' }}
              </template>

              <template v-slot:top>
                <v-toolbar flat style="height: 0px;">
                  <v-spacer></v-spacer>
                  <v-dialog dark v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete event ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  Updating with new data....
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({
      headers: [
        { text: "Event Name", value: "EventName"},
        { text: "Year", value: "Year"},
        { text: "ShowIn POS", value: "ShowInPOS"},
        { text: "Card Surcharge (%)", value: "CardSurchargePercentage"},
        { text: "Payment Profile", value: "PaymentProfile"},
        { text: "Printing Profile", value: "PrintingProfile"},
        { text: "Standard Drink Limit", value: "StandardDrinkLimit"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      dialogDelete: false,
      editedItem: [],
      defaultItem: []
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter', 'allEvents']),
      eventList: {
        get() {
          return this.allEvents;
        },
        set (val) {
          this.setAllEvents(val)
        }
      }
    },
  
    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
  
    created() {

    },
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter', 'setAllEvents']),
      ...mapActions('admin', ['createEvent']),
      editItem (item) {

        localStorage.setItem('setEventObj', JSON.stringify(item))
  
        this.$router.push({
          // path: '/event_configuration',
          name: 'Event Configuration',
          params: { id: item.EventId }
        })

      },

      deleteItem (item) {
        this.editedIndex = this.eventList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.eventList.splice(this.editedIndex, 1)

        try {
          if (this.editedItem.EventId) {
              let event_item = [
                  {
                      Type: "Event",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createEvent(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)
                  iziToast.success({
                      title: 'Event',
                      message: 'Successfully deleted record!',
                      position: 'topRight'
                  });
              }).catch(err => {
                  console.log("err")
                  console.log(err)
                  iziToast.warning({
                      title: 'Event',
                      message: 'Failed to delete record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      haddleClick: function () {
        this.$router.push({
          // path: '/event_configuration',
          name: 'Event Configuration',
          params: { id: 0 }
        })
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>